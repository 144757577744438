// src/pages/tickets.js

import React, {useState} from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import {ContainerLg, ExternalLinkButton} from '../components/components';
import Ticket from '../components/ticket';
import PurchaseTicketModal from '../components/purchase-ticket-modal';
import {Counter} from '../components/counter';

const ticketData = [
  {
    title: 'Early Bird',
    price: '€ 200',
    message: 'Limited number',
    colors: {
      background: '#f3e11a',
      text: '#000000'
    },
    isSoldOut: true,
    available: false
  },
  {
    title: 'Regular',
    price: '€ 280',
    message: 'Until 15, November',
    colors: {
      background: '#43d3e8',
      text: '#000000'
    },
    isSoldOut: false,
    available: true
  },
  {
    title: 'Late Bird',
    price: '€ 350',
    message: 'Final price',
    colors: {
      background: '#102f55',
      text: '#f3e11a'
    },
    isSoldOut: false,
    available: false
  }
];

const StyledTicketsContainer = styled.div.attrs({
  className: 'tickets-container'
})`
  margin: 2rem 0;
  .container-lg {
    h1,
    h2 {
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      margin-bottom: 0;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 2rem;
    }
    .ticket-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      > * {
        flex: 1 1 0;
        min-width: 280px;
        max-width: 280px;
      }
    }
  }
`;

const TicketsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout title="Tickets - Armada JS 2024">
      <StyledTicketsContainer>
        {/* <Counter
          dateAndTime={{year: 2024, month: 9, day: 30, hour: 12, minute: 0}}
          isTicketsPage
          label="Tickets sales starts in: "
        /> */}
        <ContainerLg>
          <h1>TICKET SALES ARE OPEN!</h1>
          <h2>🎁 Special Offer: Buy 3 Tickets, Get 1 Free! 🎁</h2>
          {/* <h1>
            TICKET SALES STARTS ON SEPTEMBER, 30<sup>th</sup>
          </h1> */}

          <div className="ticket-list">
            {ticketData.map((ticket, index) => (
              <Ticket key={index} ticket={ticket} handleClick={openModal} />
            ))}
          </div>

          <PurchaseTicketModal isOpen={isModalOpen} onClose={closeModal} />
          <div>
            <h3>Special Offer: Buy 3 Tickets, Get 1 Free!</h3>
            <ul>
              <li>
                For a limited time only, when you purchase three tickets to Armada JS, you'll
                receive a fourth ticket absolutely free!
              </li>
              <li>
                Gather your team or bring along fellow developers to experience two full days of
                expert talks, networking, and the latest in JavaScript trends at one of the largest
                JavaScript conferences in Southeastern Europe.
              </li>
              <li>
                Don't miss out— this offer won't last long. Secure your tickets today and take
                advantage of this exclusive deal!
              </li>
              <li>
                Once you have purchased 3 tickets, reach out to us at{' '}
                <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a> to get your fourth
                ticket.
              </li>
            </ul>
          </div>
          <div>
            <h3>Ticket includes:</h3>
            <ul>
              <li>Access to all conference sections</li>
              <li>Two days of lectures - Single track format</li>
              <li>Coffee breaks with free drinks</li>
              <li>Lunch on both days</li>
              <li>Access to the expo section</li>
              <li>Access to the official conference party</li>
              <li>Official conference t-shirt</li>
              <li>Awesome time!</li>
            </ul>
          </div>

          {/* Additional Content */}
          <div>
            <h3>For companies / Za kompanije:</h3>
            <p>
              We can provide an invoice for the tickets that can be paid directly to our account.
              Reach out to: <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
            </p>
            <p>
              Možemo da izdamo fakturu za željeni broj ulaznica koja se može platiti direktno preko
              računa. Mi smo u sistemu PDV-a. Za više informacija:{' '}
              <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
            </p>
          </div>
          <h2>Subscribe to our e-mail list to get the latest info</h2>
          <ExternalLinkButton label="SUBSCRIBE NOW" url="http://eepurl.com/gnVWPH" />
        </ContainerLg>
      </StyledTicketsContainer>
    </Layout>
  );
};

export default TicketsPage;
